import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCarCrash } from "@fortawesome/pro-duotone-svg-icons";

class Header extends Component {
  render() {
    return (
      // HTML
      <header class="header-global">
        <nav
          id="navbar-main"
          class="navbar navbar-main navbar-expand-lg navbar-dark navbar-theme-primary headroom py-lg-2 px-lg-6"
        >
          <div class="container">
            <a class="navbar-brand @@logo_classes" href="../index.html">
              <div class="d-flex align-items-center">
                <FontAwesomeIcon icon={faCarCrash} size="4x" />
              </div>
            </a>
            <div class="navbar-collapse collapse" id="navbar_global">
              <div class="navbar-collapse-header">
                <div class="row">
                  <div class="col-6 collapse-brand">
                    <a class="d-flex align-items-center" href="../index.html">
                      <img src="../assets/img/brand/dark.svg" alt="Logo dark" />
                    </a>
                  </div>
                  <div class="col-6 collapse-close">
                    <a
                      href="#navbar_global"
                      class="fas fa-times"
                      data-toggle="collapse"
                      data-target="#navbar_global"
                      aria-controls="navbar_global"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    ></a>
                  </div>
                </div>
              </div>
              <ul class="navbar-nav navbar-nav-hover justify-content-center">
                <li class="nav-item">
                  <a href="#" class="nav-link">
                    Home
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#team" class="nav-link">
                    Team
                  </a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link">
                    Services
                  </a>
                </li>
              </ul>
            </div>

            <div class="d-flex d-lg-none align-items-center ml-auto">
              <button
                class="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbar_global"
                aria-controls="navbar_global"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Header;
