import React, { Component } from 'react';
import Header from '../Components/header';
import Footer from '../Components/footer';
import InstagramFeed from 'react-ig-feed';
import 'react-ig-feed/dist/index.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faUserTie } from '@fortawesome/pro-duotone-svg-icons';
import { faCarMechanic } from '@fortawesome/pro-duotone-svg-icons';

class Home extends Component {
  render() {
    return (
      <>
        <Header />

        {/* <div class="preloader bg-soft flex-column justify-content-center align-items-center">
            <img
              class="loader-element"
              src="../assets/img/brand/dark.svg"
              height="50"
              alt="Rocket logo"
            />
          </div> */}

        <section class="section-header pb-7 pb-lg-11 bg-primary text-white">
          <div class="container">
            <div class="row justify-content-between align-items-center">
              <div class="col-12 col-lg-6">
                <h2 class="h4 font-weight-normal text-muted">
                  A Dent Systems Company
                </h2>
                <h1 class="display-1 mb-4">Roy's Auto Recon</h1>
                <p class="lead mb-3 mb-lg-5">
                  Your time is precious, so our team of dedicated specialists
                  will come to you and fix any automotive cosmetic damage. Just
                  use the contact card to the right and we will contact you ASAP
                </p>
                <a
                  class="btn btn-secondary animate-up-2 mb-5 mb-lg-0"
                  target="_blank"
                  href="https://themesberg.com/docs/rocket/getting-started/overview/"
                >
                  <i class="fas fa-user-tie"> Meet the team</i>
                </a>
              </div>
              <div class="col-12 col-lg-5">
                <div class="profile-card mb-7" style={{ marginBottom: 0 }}>
                  <div class="card shadow-soft border-light text-center text-black">
                    <div class="profile-image">
                      <img
                        src="../assets/img/team/profile-picture-1.jpg"
                        class="card-img-top "
                        alt="image"
                      />
                    </div>
                    <div class="card-body mt-n5">
                      <h5 class="card-title">Roy Saravi</h5>
                      <h6 class="card-subtitle">Owner</h6>
                      <p class="card-text my-2">
                        Some quick example text to build on the card title and
                        make up the bulk of the card's content.
                      </p>
                      <ul class="social-buttons py-3">
                        <li>
                          <a
                            href="#"
                            target="_blank"
                            class="btn btn-link btn-facebook"
                          >
                            <i class="fab fa-facebook-f"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            target="_blank"
                            class="btn btn-link btn-twitter"
                          >
                            <i class="fab fa-twitter"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            target="_blank"
                            class="btn btn-link btn-slack"
                          >
                            <i class="fab fa-slack-hash"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="#"
                            target="_blank"
                            class="btn btn-link btn-dribbble"
                          >
                            <i class="fab fa-dribbble"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="col-12 col-lg-5">
                <div class="card shadow-sm text-dark p-4">
                  <div class="card-body p-2">
                    <form action="#">
                      <div class="form-group mb-4">
                        <label class="h6 text-dark" for="full-name">
                          Full Name
                        </label>
                        <input
                          id="full-name"
                          name="contact-name"
                          type="text"
                          class="form-control"
                          placeholder="Ex. Roy Saravi"
                          required
                        />
                      </div>
                      <div class="form-group mb-4">
                        <label class="h6 text-dark" for="email">
                          Email Address
                        </label>
                        <input
                          id="email"
                          name="contact-email"
                          type="email"
                          class="form-control"
                          placeholder="roy@rocketbumper.com"
                          required
                        />
                      </div>
                      <div class="form-group mb-4">
                        <label class="h6 text-dark" for="email">
                          Phone Number
                        </label>
                        <input
                          id="phone"
                          name="contact-phne"
                          type="phone"
                          class="form-control"
                          placeholder="555-555-1234"
                          required
                        />
                      </div>

                      <button
                        class="btn btn-primary btn-block btn-loading"
                        type="submit"
                      >
                        <i class="far fa-shipping-fast"> Request a Quote</i>
                      </button>
                    </form>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div class="pattern bottom"></div>
        </section>
        <section class="section section-lg section-team ``bg-white">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 col-lg-8 text-center">
                <h1 class="display-2 mb-3">
                  Our Work <FontAwesomeIcon icon={faInstagram} />
                </h1>
                <a id="#team"></a>
                <p class="lead">
                  Click the images below to see the the before and after photos
                  of our recent projects.
                </p>
              </div>
              <InstagramFeed
                token="IGQVJYUWpnMUxoQ1NEb0FvNHA0SnNJUFJiSWJCUGViWGRuZAXpKSldyZAmU1NTM2dnRYWW5TaDJjLXpwdVVVakhsclBkVkFVUTBtT3BQQlg1WUg0bjdWYmxqS3hFY2htTWhaS0RLRzZATcGUyZAzdkQzBiRgZDZD"
                counter="6"
              />
            </div>
          </div>
        </section>
        <section class="section-header pb-9 pb-md-11 pb-lg-13 mb-4 mb-lg-6 bg-primary text-white">
          <div class="pattern top"></div>
          <div class="container">
            <div class="row justify-content-center">
              <div class="container">
                <div class="row justify-content-center mb-6">
                  <div class="col-12 col-md-8 text-center">
                    <h1 class="display-2 mb-3">
                      Our Services <FontAwesomeIcon icon={faCarMechanic} />
                    </h1>
                    <p class="lead">We add new services regularly.</p>
                  </div>
                </div>
                <div class="row mt-6">
                  <div class="col-12 col-md-6">
                    <div class="card shadow-soft border-light mb-4 text-black">
                      <div class="card-body">
                        <div class="d-flex flex-column flex-lg-row p-3">
                          <div class="mb-3 mb-lg-0">
                            <div class="icon icon-primary">
                              <span class="far fa-life-ring"></span>
                            </div>
                          </div>
                          <div class="pl-lg-4">
                            <h5 class="mb-3">Bumper Replacement</h5>
                            <p>Placeholder text</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="card shadow-soft border-light mb-4 text-black">
                      <div class="card-body">
                        <div class="d-flex flex-column flex-lg-row p-3">
                          <div class="mb-3 mb-lg-0">
                            <div class="icon icon-primary">
                              <span class="fas fa-street-view"></span>
                            </div>
                          </div>
                          <div class="pl-lg-4">
                            <h5 class="mb-3">Paint touchups</h5>
                            <p>Placeholder text</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-secondary animate-up-2"
                  data-toggle="modal"
                  data-target=".pricing-modal"
                >
                  <span class="mr-2">
                    <i class="fas fa-hand-pointer"></i>
                  </span>
                  Back to the top
                </button>
              </div>
            </div>
          </div>
          <div class="pattern bottom"></div>
        </section>

        <section class="section section-lg pt-0">
          <div class="row justify-content-center">
            <div class="col-12 col-md-8 text-center">
              <h1 class="display-2 mb-3">
                Request a quote <FontAwesomeIcon icon={faCarMechanic} />
              </h1>
              <p class="lead">We add new services regularly.</p>
            </div>
            <div class="col-12 col-lg-5">
              <div class="form card shadow-sm text-dark p-4">
                <div class="card-body p-2">
                  <form action="#">
                    <div class="form-group mb-4">
                      <label class="h6 text-dark" for="full-name">
                        Full Name
                      </label>
                      <input
                        id="full-name"
                        name="contact-name"
                        type="text"
                        class="form-control"
                        placeholder="Ex. Roy Saravi"
                        required
                      />
                    </div>
                    <div class="form-group mb-4">
                      <label class="h6 text-dark" for="email">
                        Email Address
                      </label>
                      <input
                        id="email"
                        name="contact-email"
                        type="email"
                        class="form-control"
                        placeholder="roy@rocketbumper.com"
                        required
                      />
                    </div>
                    <div class="form-group mb-4">
                      <label class="h6 text-dark" for="email">
                        Phone Number
                      </label>
                      <input
                        id="phone"
                        name="contact-phne"
                        type="phone"
                        class="form-control"
                        placeholder="555-555-1234"
                        required
                      />
                    </div>

                    <button
                      class="btn btn-primary btn-block btn-loading"
                      type="submit"
                    >
                      <i class="far fa-shipping-fast"> Request a Quote</i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="bg-soft">
          <div class="container">
            <div class="row" style={{ paddingTop: 30 }}>
              <div class="col-12 col-lg-6 text-center px-4 mb-5">
                <div class="icon icon-sm icon-shape icon-shape-primary rounded mb-4">
                  <i class="fas fa-envelope-open-text"></i>
                </div>
                <h5 class="mb-3">Email us</h5>
                <p class="px-5 px-sm-7 px-lg-0">
                  Email us for general queries or support
                </p>
                <a
                  class="font-weight-bold text-primary"
                  href="mailto:office@company.com"
                >
                  dentsystems@icloud.com
                </a>
              </div>
              <div class="col-12 col-lg-6 text-center px-4 mb-5">
                <div class="icon icon-sm icon-shape icon-shape-primary rounded mb-4">
                  <i class="fas fa-phone-volume"></i>
                </div>
                <h5 class="mb-3">Call us</h5>
                <p class="px-5 px-sm-7 px-lg-0">
                  Call us to speak to a member of our team.We are always happy
                  to help you.
                </p>
                <a class="font-weight-bold text-primary" href="#">
                  703-338-2500
                </a>
              </div>
              {/* <div class="col-12 col-lg-4 text-center px-4">
                  <div class="icon icon-sm icon-shape icon-shape-primary rounded mb-4">
                    <i class="fab fa-slack"></i>
                  </div>
                  <h5 class="mb-3">Slack</h5>
                  <p class="px-5 px-sm-7 px-lg-0">
                    Join our Slack community to chat with us and hundreds of
                    Dovetail users.
                  </p>
                  <a class="btn btn-sm btn-outline-primary" href="#">
                    Join our Slack{' '}
                    <span class="fas fa-long-arrow-alt-right ml-2"></span>
                  </a>
                </div> */}
            </div>
          </div>
        </section>
        <Footer />
      </>
    );
  }
}

export default Home;
