import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      // HTML

      <footer class="footer section pt-6 pt-md-8 pt-lg-10 pb-3 bg-primary text-white overflow-hidden">
        <div class="pattern top pattern-soft"></div>
        <div class="container">
          <div class="row">
            <div class="col-lg-12 mb-4 mb-lg-0">
              <a class="footer-brand mr-lg-5 d-flex" href="./index.html">
                <img
                  src="./assets/img/brand/light.svg"
                  class="mr-3"
                  alt="Footer logo"
                />
                <h4>Roy's Auto Recon</h4>
              </a>
              <p class="my-4">Some closing text here</p>
            </div>
          </div>
          <div class="my-4 my-lg-5">
            <div class="row"></div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
